<template>
  <div class="final-step-container">
    <div class="header">
      <div ref="lottiefinalstep" class="lottie-final-step" />
      <h2 class="congrat">Félicitations !</h2>
    </div>

    <span>La personnalisation de votre plan comptable est maintenant terminée :</span>

    <div class="list-wrapper">
      <div v-for="(label, index) in descriptionsLabel" :key="index" class="label-container">
        <uds-icon icon-name="fiber_manual_record" :color="udsColors.udsHasData" size="xsmall" />
        <span class="label">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import lottieWeb from "lottie-web/build/player/lottie_light.js";
import { Sector, businessSectors } from "@silexpert/core";
import validateAnimation from "@/assets/animations/validate.json";

const udsColors = getUdsColors();
const settingsAccountingPlanStore = useSettingAccountingPlanStore();

const animation = ref<any>(null);
const lottiefinalstep = ref<HTMLElement | null>(null);

const descriptionsLabel = computed<string[]>(() => {
  const form = settingsAccountingPlanStore.form;
  const idSectors = [];
  const result: string[] = [];

  for (const key in form) {
    const sector = form[key];
    if (sector.idSector !== null && sector.isSelected && sector.idSector !== Sector.VAT) {
      idSectors.push(sector.idSector);
    } else if (sector.tag === "VAT" && !sector.isSelected) {
      idSectors.push(Sector.VAT);
    }
  }

  for (const key in businessSectors) {
    const sector = businessSectors[key];
    if (sector.description) {
      const descriptions = sector.description;
      const activatedDescriptions = descriptions.filter((description) => description.activated);
      const deactivatedDescriptions = descriptions.filter((description) => !description.activated);

      if (idSectors.includes(sector.id)) {
        result.push(...activatedDescriptions.map((description) => description.label));
      } else {
        result.push(...deactivatedDescriptions.map((description) => description.label));
      }
    }
  }

  return result.filter((r) => isDefined(r));
});

onMounted(() => {
  if (!import.meta.server) {
    if (isDefined(lottieWeb)) {
      animation.value = lottieWeb.loadAnimation({
        // @ts-expect-error
        container: lottiefinalstep.value as HTMLElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: validateAnimation,
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.final-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  font-weight: $uds-weight-450;
  font-size: 16px;
  color: $uds-neutral-900;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .congrat {
      font-weight: $uds-weight-700;
      font-size: 24px;
      margin: $uds-spacing-1_5 0 $uds-spacing-3 0;
    }
  }

  .lottie-final-step {
    height: 100px;
    width: 100px;
  }

  .list-wrapper {
    border: 1px solid $uds-neutral-400;
    border-radius: $uds-radius-1;
    width: auto;
    height: 287px;
    margin: $uds-spacing-2 0 $uds-spacing-3 0;
    padding: $uds-spacing-2;
    overflow: auto;

    .label-container {
      display: flex;
      margin-bottom: $uds-spacing-2;
      align-items: center;
      gap: $uds-spacing-1;
    }
  }

  .info-wrapper {
    display: flex;
    gap: $uds-spacing-1;
    border: 1px solid $uds-primary-100;
    background-color: $uds-primary-50;
    padding: $uds-spacing-2;
    border-radius: $uds-radius-1;

    .link {
      color: $uds-primary-500;
      cursor: pointer;
    }
  }
}
</style>
