<template>
  <div>
    <p class="uds-paragraph">
      Quel est votre type d'activité ?
      <span class="second-step-title-info">(Sélectionnez une ou plusieurs réponses)</span>
    </p>
    <div class="question-grid">
      <uds-input-checkbox
        class="sector sector-checkbox"
        label="Prestation de services"
        :is-checked="settingsAccountingPlanStore.form.SERVICE.isSelected === true"
        @input="update($event, 'SERVICE')"
      />
      <uds-input-checkbox
        class="sector sector-checkbox"
        label="Vente de marchandises"
        :is-checked="settingsAccountingPlanStore.form.MARCHANDISES.isSelected === true"
        @input="update($event, 'MARCHANDISES')"
      />
      <uds-input-checkbox
        class="sector sector-checkbox"
        label="Vente de produits finis"
        :is-checked="settingsAccountingPlanStore.form.PRODUITS.isSelected === true"
        @input="update($event, 'PRODUITS')"
      />
    </div>

    <p class="uds-paragraph">Quel est votre type d'imposition ?</p>
    <div class="question-grid">
      <uds-radio-square
        class="sector"
        item-text="Impôt sur les sociétés (IS)"
        :selected="settingsAccountingPlanStore.form.IS.isSelected === true"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.IS.isSelected = true"
      />
      <uds-radio-square
        class="sector"
        item-text="Impôt sur le revenu (IR)"
        :selected="settingsAccountingPlanStore.form.IS.isSelected === false"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.IS.isSelected = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const settingsAccountingPlanStore = useSettingAccountingPlanStore();

function update(value: boolean, key: string) {
  settingsAccountingPlanStore.form[key]!.isSelected = value;
}
</script>

<style lang="scss" scoped>
.second-step-title-info {
  color: $uds-neutral-800;
}
.question-grid {
  display: grid;
  gap: $uds-spacing-2;
  grid-template-columns: 1fr 1fr;

  .sector {
    display: flex;
    height: 56px;
    width: auto;
    cursor: pointer;
    padding: 0px $uds-spacing-3;
    align-items: center;
    justify-content: flex-start;
    border-radius: $uds-radius-1;
    border: 1px solid $uds-neutral-500;

    &.selected {
      border-color: $uds-primary-300 !important;
    }
  }

  :deep(.uds-checkbox-container.sector.sector-checkbox) {
    padding: unset;
  }

  :deep(.uds-checkbox-container.sector.sector-checkbox.checked) {
    background-color: $uds-primary-50 !important;
    border-color: $uds-primary-300 !important;

    &:hover {
      background-color: $uds-primary-100 !important;
    }
  }

  .sector-checkbox {
    :deep(.checkbox-wrapper.uds-input-label) {
      height: 100%;
      width: 100%;
      justify-content: flex-start;
      margin: unset;
      margin-left: $uds-spacing-3;
      font-size: 16px;
      color: $uds-neutral-900;
    }
    :deep(.checkbox-wrapper.uds-input-label .mdi-container.checkmark) {
      top: auto;
      left: 10%;
    }
  }
}
</style>
