<template>
  <div>
    <template v-if="isSARLOrEURL">
      <p class="uds-paragraph">Êtes-vous gérant majoritaire ?</p>
      <div class="question-grid">
        <uds-radio-square
          class="sector"
          item-text="Oui"
          :selected="settingsAccountingPlanStore.form.GERANCE_MAJORITAIRE.isSelected === true"
          item-icon="target"
          @click="settingsAccountingPlanStore.form.GERANCE_MAJORITAIRE.isSelected = true"
        />
        <uds-radio-square
          class="sector"
          item-text="Non"
          :selected="settingsAccountingPlanStore.form.GERANCE_MAJORITAIRE.isSelected === false"
          item-icon="target"
          @click="settingsAccountingPlanStore.form.GERANCE_MAJORITAIRE.isSelected = false"
        />
      </div>
    </template>

    <p class="uds-paragraph">Êtes-vous soumis à la TVA ?</p>

    <div class="question-grid">
      <uds-radio-square
        class="sector"
        item-text="Oui"
        :selected="settingsAccountingPlanStore.form.VAT.isSelected === true"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.VAT.isSelected = true"
      />
      <uds-radio-square
        class="sector"
        item-text="Non"
        :selected="settingsAccountingPlanStore.form.VAT.isSelected === false"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.VAT.isSelected = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LegalFormTypeEnum } from "@silexpert/core";

const settingsAccountingPlanStore = useSettingAccountingPlanStore();
const societyStore = useSocietyStore();

const isSARLOrEURL = computed<boolean>(() => {
  return [LegalFormTypeEnum.SARL, LegalFormTypeEnum.EURL].includes(
    societyStore.society!.idLegalForm!,
  );
});
</script>

<style lang="scss" scoped>
.question-grid {
  display: grid;
  gap: $uds-spacing-2;
  grid-template-columns: 1fr 1fr;

  .sector {
    display: flex;
    height: 56px;
    width: auto;
    cursor: pointer;
    padding: 0px $uds-spacing-3;
    align-items: center;
    justify-content: flex-start;
    border-radius: $uds-radius-1;
    border: 1px solid $uds-neutral-500;

    &.selected {
      border-color: $uds-primary-300 !important;
    }
  }
}
</style>
