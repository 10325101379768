<template>
  <div>
    <div class="uds-paragraph">
      Afin de personnaliser au mieux votre expérience de
      {{ useBrandsComposable().getBrandName() }}, nous avons besoin de plus d'informations au sujet
      de votre société.
    </div>

    <p class="uds-paragraph estimate-time">
      <uds-icon icon-name="schedule" size="xsmall" :color="getUdsColors().udsNeutral800" outlined />
      Temps estimé : 3 min.
    </p>

    <p class="uds-paragraph">Sélectionnez votre secteur d'activité :</p>

    <div class="sector-grid">
      <div v-for="sector in sectorsFiltered" :key="sector.id">
        <div class="sector" :class="{ selected: isSelected(sector.id) }" @click="chose(sector.id)">
          <div class="wrapper">
            <uds-squircle-icon
              size="xsmall"
              :icon="sector.icon"
              :color="sectorColor(sector.icon)"
            />
            <div class="sector-name">
              {{ sector.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BusinessSectorTypeContent} from "@silexpert/core";
import { businessSectors } from "@silexpert/core";

defineEmits(["cancel"]);

const settingsAccountingPlanStore = useSettingAccountingPlanStore();

const sectorsFiltered = computed<BusinessSectorTypeContent[]>(() => {
  const sectors = Object.values(businessSectors);
  return sectors.filter((sector) => !sector.module);
});

function chose(id: number) {
  settingsAccountingPlanStore.form.BASE_PLAN.idSector = id;
  settingsAccountingPlanStore.form.BASE_PLAN.isSelected = !!id;
}

function isSelected(idSector: number): boolean {
  return settingsAccountingPlanStore.form.BASE_PLAN.idSector === idSector;
}

const udsColors = getUdsColors();

function sectorColor(iconName: string | undefined): string {
  switch (iconName) {
    case "work":
      return udsColors.udsNeutral800;
    case "language":
      return udsColors.udsCerulean500;
    case "person":
      return udsColors.udsCaramel500;
    case "home":
      return udsColors.udsGuacamole500;
    case "construction":
      return udsColors.udsOrange500;
    case "coffee":
      return udsColors.udsIris500;
    case "florist":
      return udsColors.udsBasil500;
    case "favorite":
      return udsColors.udsBubblegum500;
    case "taxi":
      return udsColors.udsFire500;
    default:
      return "";
  }
}
</script>

<style lang="scss" scoped>
.estimate-time {
  color: $uds-neutral-800;
  display: flex;
  align-items: center;
  gap: $uds-spacing-1;
}

.sector-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $uds-spacing-1;
  width: 100%;

  .sector {
    display: flex;
    height: 56px;
    align-items: center;
    border-radius: $uds-radius-1;
    border: 1px solid $uds-neutral-500;
    padding: 0 $uds-spacing-2;
    cursor: pointer;
    gap: 6px;

    &:hover {
      border: 1px solid $uds-primary-300;
      cursor: pointer;
    }

    &.selected {
      border: 1px solid $uds-primary-300;
      background-color: $uds-primary-50;

      &:hover {
        background-color: $uds-primary-100;
      }
    }

    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $uds-spacing-1_5;
      justify-content: flex-start;
      :deep(.squircle) {
        flex: none;
      }
    }
  }
}
</style>
