<template>
  <div>
    <p class="uds-paragraph">Encaissez-vous des espèces ?</p>
    <div class="question-grid">
      <uds-radio-square
        class="sector"
        item-text="Oui"
        :selected="settingsAccountingPlanStore.form.ESPECES.isSelected === true"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.ESPECES.isSelected = true"
      />
      <uds-radio-square
        class="sector"
        item-text="Non"
        :selected="settingsAccountingPlanStore.form.ESPECES.isSelected === false"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.ESPECES.isSelected = false"
      />
    </div>

    <p class="uds-paragraph">Utilisez-vous un ou plusieurs véhicules professionnels ?</p>

    <div class="question-grid">
      <uds-radio-square
        class="sector"
        item-text="Oui"
        :selected="settingsAccountingPlanStore.form.VEHICULE.isSelected === true"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.VEHICULE.isSelected = true"
      />
      <uds-radio-square
        class="sector"
        item-text="Non"
        :selected="settingsAccountingPlanStore.form.VEHICULE.isSelected === false"
        item-icon="target"
        @click="settingsAccountingPlanStore.form.VEHICULE.isSelected = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const settingsAccountingPlanStore = useSettingAccountingPlanStore();
</script>

<style lang="scss" scoped>
.question-grid {
  display: grid;
  gap: $uds-spacing-2;
  grid-template-columns: 1fr 1fr;

  .sector {
    display: flex;
    height: 56px;
    width: auto;
    cursor: pointer;
    padding: 0px $uds-spacing-3;
    align-items: center;
    justify-content: flex-start;
    border-radius: $uds-radius-1;
    border: 1px solid $uds-neutral-500;

    &.selected {
      border-color: $uds-primary-300 !important;
    }
  }
}
</style>
