<template>
  <div class="accounting-plan-header">
    <img src="@/assets/svg/gearAccounting.svg" alt="accountingPlanGear" >

    <div class="stepper-percent" :class="{ disabled: step === 0 }">
      <div class="stepper-info">
        <span>Création de votre plan comptable personnalisé</span>
        <span class="percent" :class="{ light: percentage === 0 }">{{ percentage }}%</span>
      </div>

      <div class="stepper-wrapper">
        <div class="stepper" :style="{ width: (step / 4) * 100 + '%' }"/>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const settingsAccountingPlanStore = useSettingAccountingPlanStore();

const step = computed(() => settingsAccountingPlanStore.step);
const percentage = computed(() => Math.round((step.value / 4) * 100));
</script>

<style lang="scss" scoped>
.accounting-plan-header {
  text-align: center;
  .stepper-percent {
    margin-top: $uds-spacing-3;
    margin-bottom: $uds-spacing-3;
    .stepper-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: $uds-spacing-1;
      .percent {
        color: $uds-primary-500;
        font-weight: $uds-weight-700;
      }

      .light {
        color: $uds-neutral-700;
      }
    }

    .stepper-wrapper {
      border: 1px solid $uds-neutral-300;
      height: 8px;
      background-color: $uds-neutral-300;
      width: 100%;
      border-radius: $uds-spacing-4;

      .stepper {
        background-color: $uds-primary-500;
        border-radius: $uds-spacing-4;
        height: 100%;
      }
    }
    &.disabled {
      * {
        color: $uds-neutral-700;
      }
    }
  }
}
</style>
