<template>
  <div class="loader">
    <div class="custom-loader"/>
    <p class="uds-headers --h4">Création de votre plan comptable personnalisé...</p>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
  color: $uds-neutral-800;
  margin-top: 20px;
  margin-bottom: 20px;

  .custom-loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #edf2ff;
    border-right-color: #1656ff;
    animation: s2 1s infinite linear;
  }
  @keyframes s2 {
    to {
      transform: rotate(1turn);
    }
  }
  span {
    margin-top: $uds-spacing-4;
  }
}
</style>
