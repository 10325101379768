<template>
  <CommonsModalsModalBody height="720px" width="634px" disable-click-outside>
    <template #title> Personnalisation du plan comptable </template>

    <template #content>
      <CommonsModalsAccountingPlanHeader v-if="step < 4" />
      <CommonsModalsAccountingPlanStep1 v-if="step === 0" />
      <CommonsModalsAccountingPlanStep2 v-if="step === 1" />
      <CommonsModalsAccountingPlanStep3 v-if="step === 2" />
      <CommonsModalsAccountingPlanStep4 v-if="step === 3" />
      <CommonsModalsAccountingPlanStep5 v-if="step === 4" />
      <CommonsModalsAccountingPlanStep6 v-if="step === 5" />
    </template>

    <template #footer>
      <template v-if="step !== 4">
        <uds-main-button type="secondary" size="small" @click="goToPreviousStep()">
          {{ step === 0 ? "Annuler" : step === 5 ? "Modifier" : "Étape précédente" }}
        </uds-main-button>
        <uds-main-button size="small" :loading="isLoading" @click="goToNextStep()">{{
          step === 0 ? "Commencer" : step === 5 ? "Terminer" : "Suivant"
        }}</uds-main-button>
      </template>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { Sector } from "@silexpert/core";

const emit = defineEmits(["cancel", "close"]);

const settingsAccountingPlanStore = useSettingAccountingPlanStore();
const societyStore = useSocietyStore();
const categoryStore = useCategoryStore();

const isLoading = ref(true);
await settingsAccountingPlanStore.fetchBusinessSectors();
settingsAccountingPlanStore.feedBusinessSectorsForm();
isLoading.value = false;

const step = computed(() => settingsAccountingPlanStore.step);
const allSectors = Object.values(Sector).filter((v) => !isNaN(Number(v)));

async function goToNextStep() {
  settingsAccountingPlanStore.step += 1;
  if (step.value === 4) {
    const idSectors: Sector[] = [];

    for (const key in settingsAccountingPlanStore.form) {
      const sector = settingsAccountingPlanStore.form[key];
      if (sector.idSector !== null && sector.idSector !== Sector.VAT && sector.isSelected) {
        idSectors.push(sector.idSector);
      } else if (sector.tag === "VAT" && !sector.isSelected) {
        idSectors.push(Sector.VAT);
      }
    }

    const toDeletes = allSectors.filter((x) => !idSectors.includes(x as Sector)) as Sector[];

    const idSociety: number = societyStore.society!.id!;
    try {
      await Promise.all([
        $silex().businessSector.create(idSociety, { idSector: idSectors }),
        $silex().businessSector.delete(idSociety, { idSector: toDeletes }),
      ]);

      await $silex().firstStep.updateOrCreate({ hasPersonnalizedChartOfAccounts: new Date() });

      await Promise.all([
        societyStore.fetchSociety(),
        categoryStore.fetchSocietyCategories(idSociety),
        societyStore.fetchOnboardingFirstStep(),
      ]);

      settingsAccountingPlanStore.step += 1;
    } catch (error) {
      $notifier().open({ content: apiErrorToString(error) });
    }
  } else if (step.value === 6) {
    settingsAccountingPlanStore.step = 0;
    emit("close");
  }
}

function goToPreviousStep() {
  if (step.value === 0) {
    emit("cancel");
  } else if (step.value === 5) {
    settingsAccountingPlanStore.step = 0;
  } else {
    settingsAccountingPlanStore.step -= 1;
  }
}
</script>
